const $ = jQuery;

export default class Navbar {
    constructor(options = null) {}

    init() {
        this.__navBarScroll();
    }

    __navBarScroll() {
        const $ = jQuery;
        $(window).scroll(function () {
            if ($(window).scrollTop() >= 5) {
                $("#header").addClass("scrolled");
            } else {
                $("#header").removeClass("scrolled");
            }
        });
    }
}
