const $ = jQuery;

export default class HeroBanner {
    constructor(options = null) {}

    init() {
        this.__navigation();
    }

    __navigation() {
        $(document).ready(function () {
            var currentPage = window.location.pathname;
            var homeURL = $(".simplified-hero-banner-page-link")
                .first()
                .data("home-url");

            // Remove active class from all icons
            $(".simplified-hero-banner-swiper-icon").removeClass("active");

            // Add active class to the icon corresponding to the current page
            var currentPageWithHome = homeURL + currentPage;

            $(
                'a[href="' +
                    currentPageWithHome +
                    '"] .simplified-hero-banner-swiper-icon'
            ).addClass("active");
            $(
                'a[href="' +
                    currentPageWithHome +
                    '"] .hero-banner-navigation-link'
            ).addClass("active");
        });
    }
}
