const $ = jQuery;

export default class Resources {
    constructor(options = null) {}

    init() {
        this.__filters();
    }

    __filters() {
        $(document).ready(function () {
            $("#toggleFilters").click(function () {
                $("#filterForm").toggleClass("hidden");
                $(".clear-filters").toggleClass("hidden");
            });
        });
    }
}
