// import js dependencies

import AjaxPost from "./ajax";
import Swipers from "./swiper";
import Menu from "./menu";
import Resources from "./resources";
import HeroBanner from "./simplified-hero-banner";
import Navbar from "./navbar";
import AnimatedSvgs from "./animated-svgs";
import ManageConsent from "./manage-consent";

//Get Ajax Responses
jQuery(($) => {
    const swipers = new Swipers({});
    swipers.init();

    const ajaxPost = new AjaxPost({});
    ajaxPost.init();

    const menu = new Menu({});
    menu.init();

    const resources = new Resources({});
    resources.init();

    const heroBanner = new HeroBanner({});
    heroBanner.init();

    const navbar = new Navbar({});
    navbar.init();

    const animateSvgs = new AnimatedSvgs({});
    animateSvgs.init();

    const manageConsent = new ManageConsent({});
    manageConsent.init();

    window.clearFilters = function () {
        var checkboxes = document.querySelectorAll(
            ".resource-category-checkbox"
        );
        checkboxes.forEach(function (checkbox) {
            checkbox.checked = false; // Clear the checked state of all checkboxes
        });
        // Clear the query parameters in the URL
        var url = new URL(window.location.href);
        url.search = "";
        var newUrl = url.toString();
        window.history.replaceState({}, "", newUrl);
    };

    document.querySelectorAll(".accordion-button").forEach((button) => {
        button.addEventListener("click", () => {
            document.querySelectorAll(".faq-down-arrow").forEach((arrow) => {
                arrow.style.transform = "none";
            });
            const arrow = button.querySelector(".faq-down-arrow");
            if (button.getAttribute("aria-expanded") === "true") {
                arrow.style.transform = "rotate(180deg)";
            }
        });
    });

    $(document).ready(function () {
        $(".menu-item-has-children").hover(
            function () {
                if ($(".sub-menu").css("display") === "flex") {
                    $("#navigation").css("border-bottom-left-radius", "0");
                }
            },
            function () {
                if ($(".sub-menu").css("display") === "flex") {
                    $("#navigation").css("border-bottom-left-radius", "0");
                } else {
                    $("#navigation").css("border-bottom-left-radius", "40px");
                }
            }
        );
    });

    $(document).ready(function () {
        // Check if the first section with the class 'block' has the specified classes
        var firstBlock = $(".block:first-of-type");
        if (
            (firstBlock.hasClass("block-text_right_illustration_left") ||
                firstBlock.hasClass("block-text_left_illustration_right")) &&
            $("#heroBannerNavigationSwiper").closest(".top-row").length === 0
        ) {
            // Add a specific class to the first section
            firstBlock.addClass("add-margin-top");
        }
    });
});
