import Swiper from "swiper/bundle";
import "swiper/css/bundle";

const $ = jQuery;

export default class Swipers {
    constructor(options = null) {}

    init() {
        this.__swiper1();
        this.__testimonialSwiper();
        this.__resourcesSwiper();
        this.__clientStoriesSwiper();
        this.__gallerySwiper();
        // this.__heroBannerSwiperNavigation();
        // this.__heroBannerSwiper();
        this.__heroSwiper();
    }

    __swiper1() {
        const $swiperBlocks = $(".swiper_block");

        for (const swiperBlock of $swiperBlocks) {
            const swiperBlockId = $(swiperBlock).attr("id");

            const swiper = new Swiper("#" + swiperBlockId, {
                speed: 1000,
                slidesPerView: 2,
                spaceBetween: 22.5,
                autoplay: false,
                loop: false,
                navigation: {
                    nextEl: ".swiper-next." + swiperBlockId,
                    prevEl: ".swiper-prev." + swiperBlockId,
                },
            });
        }
    }
    __testimonialSwiper() {
        const $swiperBlocks = $(".testimonial_swiper_block");

        for (const swiperBlock of $swiperBlocks) {
            const swiperBlockId = $(swiperBlock).attr("id");

            const swiper = new Swiper("#" + swiperBlockId, {
                slidesPerView: 1,
                speed: 750,
                autoplay: false,
                loop: true,
                grabCursor: true,
                effect: "fade",
                fadeEffect: { crossFade: true },
                navigation: {
                    nextEl: ".swiper-next-" + swiperBlockId,
                    prevEl: ".swiper-prev-" + swiperBlockId,
                },
            });
        }
    }

    __resourcesSwiper() {
        const $swiperBlocks = $(".resources_swiper_block");

        for (const swiperBlock of $swiperBlocks) {
            const swiperBlockId = $(swiperBlock).attr("id");

            const swiper = new Swiper("#" + swiperBlockId, {
                slidesPerView: 1,
                speed: 750,
                autoplay: false,
                loop: true,
                grabCursor: true,
                effect: "fade",
                fadeEffect: { crossFade: true },
                navigation: {
                    nextEl: ".swiper-next-" + swiperBlockId,
                    prevEl: ".swiper-prev-" + swiperBlockId,
                },
            });
        }
    }

    __clientStoriesSwiper() {
        const $swiperBlocks = $(".client_stories_swiper_block");

        for (const swiperBlock of $swiperBlocks) {
            const swiperBlockId = $(swiperBlock).attr("id");

            const swiper = new Swiper("#" + swiperBlockId, {
                slidesPerView: 1,
                speed: 750,
                autoplay: false,
                loop: true,
                grabCursor: true,
                effect: "fade",
                fadeEffect: { crossFade: true },
                navigation: {
                    nextEl: ".swiper-next-" + swiperBlockId,
                    prevEl: ".swiper-prev-" + swiperBlockId,
                },
            });
        }
    }

    __gallerySwiper() {
        const $swiperBlocks = $(".gallery_swiper_block");

        for (const swiperBlock of $swiperBlocks) {
            const swiperBlockId = $(swiperBlock).attr("id");

            // Get the total number of slides
            const totalSlides = $(swiperBlock).find(".swiper-slide").length;

            const swiper = new Swiper("#" + swiperBlockId, {
                speed: 1000,
                loop: true,
                slidesPerView: 2,
                spaceBetween: 0,
                grabCursor: true,
                breakpoints: {
                    576: {
                        slidesPerView: 2,
                        spaceBetween: 0,
                    },
                    768: {
                        slidesPerView: totalSlides >= 3 ? 3 : totalSlides,
                        spaceBetween: 0,
                    },
                    992: {
                        slidesPerView: totalSlides >= 4 ? 4 : totalSlides,
                        spaceBetween: 0,
                    },
                    1200: {
                        slidesPerView: totalSlides >= 5 ? 5 : totalSlides,
                        spaceBetween: 0,
                    },
                },
                autoplay: {
                    disableOnInteraction: false, // Autoplay continues even when the user interacts with the swiper
                    enabled: false,
                },
                navigation: {
                    nextEl: ".swiper-next-" + swiperBlockId,
                    prevEl: ".swiper-prev-" + swiperBlockId,
                },
            });
            if (totalSlides > swiper.params.slidesPerView) {
                swiper.autoplay.start(); // Start autoplay if the total slides is greater than the slides per view
            }
        }
    }

    __heroSwiper() {
        function myFunctionOnSlideChange(id) {
            const element = document.getElementById(id);
            element.svgatorPlayer.ready(function () {
                this.play();
            });
        }

        const totalSlides = $(".hero_banner_swiper_block").find(
            ".swiper-slide"
        ).length;

        const swiper1 = new Swiper(".hero_banner_swiper_block", {
            slidesPerView: 2,
            watchSlidesProgress: true,
            centeredSlides: true,
            preventClicks: true,
            spaceBetween: 0,
            slideToClickedSlide: false,
            allowTouchMove: false,
            threshold: 35,
            breakpoints: {
                576: {
                    slidesPerView: 2,
                    spaceBetween: 0,
                    centeredSlides: true,
                },
                768: {
                    slidesPerView: totalSlides >= 4 ? 4 : totalSlides,
                    spaceBetween: 0,
                    centeredSlides: false,
                },
                992: {
                    slidesPerView: totalSlides >= 6 ? 6 : totalSlides,
                    spaceBetween: 0,
                    centeredSlides: false,
                    slideToClickedSlide: true,
                },
            },

            navigation: {
                nextEl: ".swiper-next-heroBannerNavigationSwiper",
                prevEl: ".swiper-prev-heroBannerNavigationSwiper",
            },

            on: {
                init: function () {
                    if (window.matchMedia("(max-width: 768px)").matches) {
                        updateNavButtons(this); // Call the function to update navigation buttons on initialization
                    }
                },
                slideChange: function () {
                    if (window.matchMedia("(max-width: 768px)").matches) {
                        updateNavButtons(this); // Call the function to update navigation buttons on slide change
                    }
                },
            },
        });
        const swiper2 = new Swiper(".main_hero_banner_swiper_block", {
            watchSlidesProgress: true,
            slidesPerView: 1,
            centeredSlides: true,
            allowTouchMove: false,
            effect: "fade",
            fadeEffect: {
                crossFade: true,
            },
            thumbs: {
                swiper: swiper1,
            },
            navigation: {
                nextEl: ".swiper-next-heroBannerNavigationSwiper",
                prevEl: ".swiper-prev-heroBannerNavigationSwiper",
            },
            on: {
                init: function () {
                    if (window.matchMedia("(max-width: 768px)").matches) {
                        updateNavButtons(this); // Call the function to update navigation buttons on initialization
                    }
                    const currentSlideElement = this.slides[this.activeIndex];
                    // Get the current slide element using the index

                    const svgElement = currentSlideElement.querySelector(
                        ".illustration-container svg"
                    );
                    const svgSwiperId = $(svgElement).attr("id");

                    myFunctionOnSlideChange(svgSwiperId);
                },
                slideChange: function () {
                    if (window.matchMedia("(max-width: 768px)").matches) {
                        updateNavButtons(this); // Call the function to update navigation buttons on slide change
                    }

                    const currentSlideIndex = swiper2.activeIndex;
                    // Get the current slide element using the index
                    const currentSlideElement =
                        swiper2.slides[currentSlideIndex];

                    const svgElement = currentSlideElement.querySelector(
                        ".illustration-container svg"
                    );

                    const svgSwiperId = $(svgElement).attr("id");

                    myFunctionOnSlideChange(svgSwiperId);
                },
            },
        });

        function updateNavButtons(swiper) {
            var prevButton = document.querySelector(
                ".swiper-prev-heroBannerNavigationSwiper"
            );
            var nextButton = document.querySelector(
                ".swiper-next-heroBannerNavigationSwiper"
            );

            if (swiper.isBeginning) {
                prevButton.style.display = "none";
            } else {
                prevButton.style.display = "block";
            }

            if (swiper.isEnd) {
                nextButton.style.display = "none";
            } else {
                nextButton.style.display = "block";
            }
        }
    }
}
