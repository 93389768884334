const $ = jQuery;

export default class AnimatedSvgs {
    constructor(options = null) {}

    init() {
        this.__animateSvgs();
    }

    __animateSvgs() {
        function myFunctionOnSlideChange(id) {
            const element = document.getElementById(id);
            element.svgatorPlayer.ready(function () {
                this.play();
            });
        }
        const svgElements = document.querySelectorAll(".illustration svg");

        const observer = new IntersectionObserver(
            (entries, observer) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        const svgElement = entry.target;
                        const svgSwiperId = $(svgElement).attr("id");
                        myFunctionOnSlideChange(svgSwiperId);
                        observer.unobserve(entry.target);
                    }
                });
            },
            { threshold: 0.75 }
        );

        svgElements.forEach((svgElement) => {
            observer.observe(svgElement);
        });
    }
}
